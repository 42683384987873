import moment from 'moment'

let DEFAULT_FORM_VALUES = {
  suggestion: '',
  createdBy: '',
  createdDate: null
}

export const data = function() {
  return {
    isDebug: true,
    loading: false,
    toggle: false,
    suggestionID: 0,
    formData: {
      ...DEFAULT_FORM_VALUES
    },
    styles: {
      mainContainer: {
        minHeight: '1000px'
      }
    },
    formatDateFn: function(value) {
      return value != null
        ? moment(value, 'YYYY/MM/DD HH:mm:ss').format('MM-DD-YYYY hh:mm:ss a')
        : ''
    }
  }
}
