<template>
  <PageContentLayoutOnly role="region" id="suggestionDetails" suggestion-details>
    <section class="section card">
      <div class="level pl-5 pr-5">
        <div class="level-right">
          <div class="level-item">
            <svg
              width="30"
              height="30"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M15 19l-7-7 7-7"
              ></path>
            </svg>
          </div>
          <div class="level-item">
            <b-button label="Back to Suggestions" @click.prevent="returnToParent()" />
          </div>
        </div>
      </div>
      <div class="box is-12">
        <ValidationObserver ref="form" v-slot="{ handleSubmit }">
          <transition name="fade" mode="out-in" appear>
            <form @submit.prevent="handleSubmit(onFormSubmit)" class="form">
              <fieldset>
                <div>
                  <valid-input
                    style="width:50px !important;"
                    name="Suggestion"
                    label="Suggestion"
                    type="textarea"
                    vid="Suggestion"
                    placeholder="Suggestion"
                    spellcheck="true"
                    aria-label="Suggestion"
                    class="is-small"
                    disabled="true"
                    v-model="formData.suggestion"
                  />
                </div>
                <div class="pt-5">
                  <valid-input
                    style="width:50px !important;"
                    name="CreatedBy"
                    label="Created By"
                    type="text"
                    vid="CreatedBy"
                    placeholder="Created By"
                    spellcheck="true"
                    aria-label="Created By"
                    class="is-small"
                    v-model="formData.createdBy"
                    disabled="true"
                  />
                </div>
                <div class="pt-5">
                  <valid-input
                    style="width:50px !important;"
                    name="CreatedDate"
                    label="Created Date"
                    type="text"
                    vid="CreatedDate"
                    placeholder="Created Date"
                    spellcheck="true"
                    aria-label="Created Date"
                    class="is-small"
                    v-model="formData.createdDate"
                    disabled="true"
                  />
                </div>
              </fieldset>
              <fieldset>
                <ValidationProvider vid="providerErrors" v-slot="{ errors }">
                  <div class="field">
                    <span>{{ errors[0] }}</span>
                  </div>
                </ValidationProvider>
              </fieldset>
            </form>
          </transition>
        </ValidationObserver>
      </div>
    </section>
  </PageContentLayoutOnly>
</template>

<script>
import PageContentLayoutOnly from '@/containers/PageContentLayoutOnly'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { mapState, mapGetters } from 'vuex'
import { data } from './keys/data'
import { methods } from './keys/methods'

// components
import ValidInput from '@/components/inputs/ValidInput'

export default {
  components: {
    PageContentLayoutOnly,
    ValidationObserver,
    ValidationProvider,
    ValidInput
  },

  data,

  computed: {
    ...mapState({
      hoaId: state => state.user.selectedHoaId,
      ...mapGetters('user', ['authUser'])
    })
  },

  mounted() {
    this.reload()
  },

  methods,

  i18n: {
    messages: {
      en: { suggestionDetail: { title: 'Suggestion Detail' } }
    }
  }
}
</script>

<style lang="scss" scoped></style>
