import { suggestionStore } from '@/services/Suggestion/store'
import { parseDate } from '@/utilities/Date/parse'
import _get from 'lodash/get'

export const methods = {
  async reload() {
    this.loading = true

    const loadingComponent = this.$buefy.loading.open({
      container: null
    })

    try {
      this.suggestionID = this.$route.params.id || 0

      if (this.isDebug === true) console.debug('suggestionID=' + this.suggestionID)

      await this.loadSuggestionDetail()
    } catch (e) {
      console.debug('error in reload: ' + e)
    }

    loadingComponent.close()

    this.loading = false
  },

  async loadSuggestionDetail() {
    await suggestionStore
      .dispatch('getSuggestionById', {
        suggestionID: this.suggestionID
      })
      .then(({ result }) => {
        if (result) {
          if (this.isDebug == true) console.debug('loadSuggestionDetail' + JSON.stringify(result))

          this.formData.suggestion = _get(result, 'suggestionText', '')
          this.formData.createdBy = _get(result, 'identityInformation.name', '')
          this.formData.createdDate = parseDate(_get(result, ['createdDate'], ''), 'LL')
        }
      })
  },

  returnToParent() {
    this.$router
      .push({
        path: '/communityCenter/suggestions#tab'
      })
      .catch(() => {})
  }
}
